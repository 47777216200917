import Rollbar from "rollbar";
import { dev } from "$app/environment";
import type { HandleClientError } from "@sveltejs/kit";

const rollbarAccessToken = import.meta.env
  .VITE_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN;

const codeVersion = import.meta.env.VITE_LATEST_SHA;
const environmentName = import.meta.env.VITE_ENVIRONMENT_NAME;

const rollbar = new Rollbar({
  accessToken: rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environmentName,
    client: {
      javascript: {
        code_version: codeVersion,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

export const handleError: HandleClientError = async ({ error }) => {
  if (!dev) rollbar.error(error);
};
